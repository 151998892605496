import {
  Chat,
  ChatMemberInfo,
  Message,
  MessageImageInfo,
  MessageReaction,
  MessageType,
  ServerTimestamp,
  SystemMessageType,
  User,
  chatDataConverter,
  messageDataConverter,
} from "@sequoiacap/shared/models";
import {
  CloudFunctionName,
  FIREBASE_WRITE_TIMEOUT,
  FunctionNameV2,
  ReadDocumentOptions,
  callCloudFunction,
  callCloudFunctions,
  uploadFile,
  usePaginate,
  useReadDocument,
  useReadQuery,
} from "./firebase/FirebaseAPI";
import { Document } from "~/network/swr-firebase/types/Document";
import {
  PaginatedResult,
  ReadDocumentReturnType,
  ReadQueryReturnType,
} from "./firebase/types";
import {
  TimeoutError,
  asyncCallWithTimeout,
} from "@sequoiacap/shared/utils/asyncCallWithTimeout";
import {
  TrackErrorEvent,
  TrackEvent,
  track,
  trackError,
} from "~/utils/analytics";
import { clearCollectionCache, fuego, mutateDocument } from "./swr-firebase";
import { cloneWith } from "lodash";
import {
  collection,
  deleteField,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getUsersByIds, useAPIGetUser } from "./user-api";
import { graphemeLength } from "~/utils/text";
import { isOnlyEmojis } from "@sequoiacap/shared/utils/emoji";
import { resizeImageFile } from "~/utils/image";
import { useCallback, useEffect, useState } from "react";
import { useGetWorkerValue } from "./firebase/network-cache-db";
import { useStorageDownloadURL } from "./swr-firebase/hooks/use-swr-storage";
import getFirebaseDataConverter from "./firebase/firebase-data-converter";
import getFirstName from "@sequoiacap/shared/utils/firstname";
import useLightUserInfo from "./useLightUserInfo";
import useSWR, { mutate } from "swr";

export function useAPIListenMyChats(): {
  error?: Error;
  loading: boolean;
  chats?: Chat[];
} {
  const { userId: loggedInUserId } = useLightUserInfo();
  const {
    loading,
    error,
    data: chats,
  } = useReadQuery(
    loggedInUserId ? "chat" : null,
    chatDataConverter,
    undefined,
    undefined,
    [`member.${loggedInUserId}.id`, "==", loggedInUserId],
    { listen: true },
  );

  return {
    loading,
    error,
    chats: chats,
  };
}

export function useAPIListenUnreadChats(): {
  error?: Error;
  loading: boolean;
  chats?: Chat[];
} {
  const { userId: loggedInUserId, isWritableUser } = useLightUserInfo();
  const {
    loading,
    error,
    data: chats,
  } = useReadQuery(
    loggedInUserId && isWritableUser ? "chat" : null,
    chatDataConverter,
    undefined,
    undefined,
    [`member.${loggedInUserId}.unread_count`, ">", 0],
    { listen: true },
  );

  return {
    loading,
    error,
    chats,
  };
}

export function useAPIGetChat(chatId: string | undefined | null): {
  error?: Error;
  loading: boolean;
  data?: Chat;
} {
  const path = `chat/${chatId}`;
  return useReadDocument(chatId ? path : null, chatDataConverter, {});
}

export function useAPIGetChatMessage(
  chatId: string | undefined | null,
  messageId: string | undefined | null,
  options: ReadDocumentOptions<Message> = {},
): ReadDocumentReturnType<Message> {
  return useReadDocument(
    chatId && messageId ? `chat/${chatId}/message/${messageId}` : null,
    messageDataConverter,
    options,
  );
}

export function useAPIGetMessagesByChat(
  chatId: string | undefined,
  limit = 20,
): PaginatedResult<Message> {
  return usePaginate(
    chatId ? `chat/${chatId}/message` : null,
    messageDataConverter,
    limit,
    ["created_at", "desc"],
  );
}

export function useAPIListenNewMessagesByChat(
  chatId: string | undefined,
  limit = 10,
): ReadQueryReturnType<Message> {
  return useReadQuery(
    chatId ? `chat/${chatId}/message` : null,
    messageDataConverter,
    limit,
    ["created_at", "desc"],
    undefined,
    {
      listen: true,
    },
  );
}

export function useAPIGetHasPendingNewMessageByChat(chatId?: string) {
  const { data, updateValue } = useGetWorkerValue(
    chatId ? ["chat", chatId, "hasNewMessage"] : undefined,
  );
  const setGotNewMessage = useCallback(() => {
    void updateValue("");
  }, [updateValue]);
  return {
    hasNewMessage: data,
    setGotNewMessage,
  };
}

const STATUS_EXPIRATION = 10000;

export function getValidatedStatus(chatMember: ChatMemberInfo): string {
  if (
    chatMember.status &&
    Date.now() - (chatMember.statusUpdatedAt?.getTime() ?? 0) <
      STATUS_EXPIRATION
  ) {
    return chatMember.status;
  }
  return "idle";
}

export function useSystemMessageText(
  message?: Message,
  chatList = false,
): {
  error?: Error;
  text?: string;
} {
  const { userId: loggedInUserId } = useLightUserInfo();
  const fromMe = message?.createdById === loggedInUserId;
  const otherSenderId =
    loggedInUserId && !fromMe ? message?.createdById ?? null : null;
  const { data: sender } = useAPIGetUser(otherSenderId);
  const [text, setText] = useState<string | undefined>(message?.text);
  const systemMessageInfo = message?.systemMessageInfo;
  const firstName = sender?.name ? getFirstName(sender.name) : null;

  useEffect(() => {
    if (!systemMessageInfo || !loggedInUserId) {
      return;
    }
    switch (systemMessageInfo.systemMessageType) {
      case SystemMessageType.created: {
        if (chatList) {
          setText(
            fromMe
              ? `You created this group.`
              : `${firstName} created this group.`,
          );
        } else {
          setText(
            `These messages are private to the members of this thread. Sequoia does not read these threads.

${fromMe ? `You created this group.` : `${firstName} created this group.`}`,
          );
        }
        break;
      }
      case SystemMessageType.createdDirectMessage: {
        if (chatList) {
          setText("");
        } else {
          setText(
            `These messages are private to the members of this thread. Sequoia does not read these threads.`,
          );
        }
        break;
      }
      case SystemMessageType.userLeft: {
        setText(
          fromMe ? `You left this group` : `${firstName} left this group`,
        );
        break;
      }
      case SystemMessageType.userJoined: {
        const userList = systemMessageInfo.addedUserIds;
        generateUserListText(loggedInUserId, userList).then((t) => {
          setText(fromMe ? `You added ${t}` : `${firstName} added ${t}`);
        }).catch;
        break;
      }
      case SystemMessageType.userRemoved: {
        const userList = systemMessageInfo.removedUserIds;
        generateUserListText(loggedInUserId, userList).then((t) => {
          setText(fromMe ? `You removed ${t}` : `${firstName} removed ${t}`);
        }).catch;
        break;
      }
      case SystemMessageType.formattedText: {
        setText(systemMessageInfo.formattedText);
        break;
      }
      default: {
        break;
      }
    }
  }, [chatList, firstName, fromMe, loggedInUserId, systemMessageInfo]);

  return {
    text,
  };
}

async function generateUserListText(
  loggedInUserId: string,
  userIds: string[] | undefined,
): Promise<string | undefined> {
  if (userIds === undefined) {
    return undefined;
  }
  const you = userIds
    .filter((userId) => userId === loggedInUserId)
    .map(() => "you");
  const otherUserIds = userIds.filter((userId) => userId !== loggedInUserId);
  const users = await getUsersByIds(otherUserIds);
  const usernames = [
    ...you,
    ...Object.values(users)
      .map((user) => user.name)
      .sort(),
  ];
  if (usernames.length === 0) {
    return "";
  }
  if (usernames.length === 1) {
    return usernames[0];
  }
  return [
    usernames.slice(0, usernames.length - 1).join(", "),
    usernames[usernames.length - 1],
  ].join(" and ");
}

export async function markChatRead(
  loggedInUserId: string | undefined | null,
  chatId: string | undefined,
): Promise<void> {
  if (!loggedInUserId || !chatId) {
    return;
  }
  console.log("markChatRead/marking chat read", chatId);
  const path = `chat/${chatId}`;
  const changes = {
    member: {
      [loggedInUserId]: {
        id: loggedInUserId,
        unread_count: 0,
        first_unread_message_id: deleteField(),
        updated_at: serverTimestamp(),
      },
    },
    updated_at: serverTimestamp(),
  };
  try {
    await mutate(
      path,
      async (current: Document<Chat> | undefined) => {
        if (
          current &&
          current.data &&
          current.data.members[loggedInUserId]?.unreadCount === 0
        ) {
          return current;
        }
        await setDoc(doc(fuego.db, path), changes, { merge: true });
        console.log("markChatRead/marked chat read", chatId);
      },
      false,
    );
  } catch (err) {
    console.error(`markChatRead/Error mark chat read; chatId=${chatId}`, err);
  }
}

export async function updateChatTyping(
  loggedInUserId: string,
  chatId: string,
  typing: boolean,
): Promise<void> {
  const path = `chat/${chatId}`;

  const changes = {
    member: {
      [loggedInUserId]: {
        status: typing ? "typing" : "idle",
        status_updated_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      },
    },
    updated_at: serverTimestamp(),
  };
  try {
    await setDoc(doc(fuego.db, path), changes, { merge: true });
  } catch (err) {
    console.error("updateChatTyping/Error updating user profile error", err);
  }
  await mutate(path);
}

export async function deleteChatMessage(
  chatId: string,
  messageId: string,
): Promise<void> {
  const path = `chat/${chatId}/message/${messageId}`;
  const changes = {
    text: "",
    deleted: true,
    updated_at: serverTimestamp(),
    deleted_at: serverTimestamp(),
    mention: deleteField(),
    image_info: deleteField(),
    modified: true,
  };
  try {
    track(TrackEvent.chatMessageDeleting, {
      chat_id: chatId,
      message_id: messageId,
    });
    await setDoc(doc(fuego.db, path), changes, { merge: true });
    track(TrackEvent.chatMessageDeleted, {
      chat_id: chatId,
      message_id: messageId,
    });
  } catch (err) {
    console.error("deleteMessage/Error", err);
    trackError(TrackErrorEvent.deleteMessage, err, {
      chatId,
      messageId,
    });
  }
  await mutateDocument(path, messageDataConverter, true);
}

// Only support editing text messages for now
// For other types of messages, we will just delete
export async function editTextMessage(
  chatId: string,
  messageId: string,
  text: string,
  mention: Record<string, { id: string; name: string }> = {},
): Promise<Message> {
  const path = `chat/${chatId}/message/${messageId}`;
  const changes = {
    text,
    mention,
    modified: true,
    updated_at: serverTimestamp(),
  };
  try {
    track(TrackEvent.chatMessageEditing, {
      chat_id: chatId,
      message_id: messageId,
    });
    await setDoc(doc(fuego.db, path), changes, { merge: true });
    track(TrackEvent.chatMessageEdited, {
      chat_id: chatId,
      message_id: messageId,
    });
  } catch (err) {
    console.error("editTextMessage/Error", err);
    trackError(TrackErrorEvent.editMessage, err, {
      chatId,
      messageId,
    });
  }
  const updated = await mutateDocument(path, messageDataConverter, true);
  if (!updated) {
    throw new Error("Error saving message");
  }
  return updated;
}

/**
 *
 * July 5, 2023: Introducing optimistic send:
 *   * If there are errors (including timeout), we will not indicate that to the user anymore.
 *   * If the user does not have permission to send a message (but has network access), sending a message will clear the input box (because of optimistic send) but the message will not appear in the message list. As of 2023/07/05, we've had 2 errors in 180d. */
export async function insertTextMessage(
  loggedInUserId: string,
  chatId: string,
  text: string,
  mention: Record<string, { id: string; name: string }> = {},
): Promise<Message> {
  // TODO: Check this
  // if authManager.isReadonly {
  //     return Promise(APIError.ReadonlyAccount)
  // }
  const messageCollectionPath = `chat/${chatId}/message`;
  const messageId = doc(collection(fuego.db, messageCollectionPath)).id;
  const path = `${messageCollectionPath}/${messageId}`;
  const messageType =
    text.length < 100 && isOnlyEmojis(text) && graphemeLength(text) <= 4
      ? MessageType.emoji
      : MessageType.text;

  const newMessage = new Message(
    messageId,
    chatId,
    ServerTimestamp.create(),
    loggedInUserId,
    messageType,
    ServerTimestamp.create(),
    text,
    undefined,
    undefined,
    undefined,
    undefined,
    mention,
  );
  track(TrackEvent.chatMessageSending, {
    chat_id: chatId,
    message_id: messageId,
    message_type: messageType,
  });
  asyncCallWithTimeout(
    setDoc(
      doc(fuego.db, path).withConverter(
        getFirebaseDataConverter(messageDataConverter),
      ),
      newMessage,
    ),
    FIREBASE_WRITE_TIMEOUT,
    `insertTextMessage(${loggedInUserId},${chatId})`,
  ).then(
    () => {
      track(TrackEvent.chatMessageSent, {
        chat_id: chatId,
        message_id: messageId,
        message_type: messageType,
      });
    },
    (err) => {
      console.error("insertTextMessage/Error", err);
      trackError(TrackErrorEvent.sendMessage, err, {
        chatId,
        message_id: messageId,
        message_type: messageType,
      });
      if (!(err instanceof TimeoutError)) {
        // Message didn't write, clear the cache
        void mutate(path, undefined);
      }
    },
  );

  // Clone the message with a real date so the cache will have something valid, even if the backend didn't write through.
  const newMessageWithDate = cloneWith(newMessage, (obj) => {
    const now = new Date();
    obj.updatedAt = now;
    obj.createdAt = now;
    return obj;
  });
  const updated = await mutate(path, newMessageWithDate);
  if (!updated) {
    throw new Error("Error saving message");
  }
  return updated;
}

export async function insertImageMessage(
  loggedInUserId: string,
  chatId: string,
  file: File,
): Promise<Message> {
  // TODO: Check this
  // if authManager.isReadonly {
  //     return Promise(APIError.ReadonlyAccount)
  // }

  const messageCollectionPath = `chat/${chatId}/message`;
  const messageId = doc(collection(fuego.db, messageCollectionPath)).id;
  const imageFileInfo = await resizeImageFile(file);

  // upload image
  const storagePath = await uploadImageFileForMessage(
    loggedInUserId,
    messageId,
    imageFileInfo.file,
  );
  console.log("message file upload to", storagePath);

  const path = `${messageCollectionPath}/${messageId}`;
  const newMessage = new Message(
    messageId,
    chatId,
    ServerTimestamp.create(),
    loggedInUserId,
    MessageType.image,
    ServerTimestamp.create(),
    undefined,
    new MessageImageInfo(
      storagePath,
      imageFileInfo.width,
      imageFileInfo.height,
    ),
  );
  track(TrackEvent.chatMessageSending, {
    chat_id: chatId,
    message_id: messageId,
    message_type: MessageType.image,
  });
  asyncCallWithTimeout(
    setDoc(
      doc(fuego.db, path).withConverter(
        getFirebaseDataConverter(messageDataConverter),
      ),
      newMessage,
    ),
    FIREBASE_WRITE_TIMEOUT,
    `insertImageMessage(${loggedInUserId},${chatId})`,
  ).then(
    () => {
      track(TrackEvent.chatMessageSent, {
        chat_id: chatId,
        message_id: messageId,
        message_type: MessageType.image,
      });
    },
    (err) => {
      console.error("insertImageMessage/Error", err);
      trackError(TrackErrorEvent.sendMessage, err, {
        chatId,
        message_id: messageId,
        message_type: MessageType.image,
      });
      if (!(err instanceof TimeoutError)) {
        // Message didn't write, clear the cache
        void mutate(path, undefined);
      }
    },
  );
  const newMessageWithDate = cloneWith(newMessage, (obj) => {
    const now = new Date();
    obj.updatedAt = now;
    obj.createdAt = now;
    return obj;
  });
  const updated = await mutate(path, newMessageWithDate);
  if (!updated) {
    throw new Error("Error saving message");
  }
  return updated;
}

function uploadImageFileForMessage(
  loggedInUserId: string,
  messageId: string,
  file: File,
): Promise<string> {
  const filename = file.name;
  const path = `upload/${loggedInUserId}/message/${messageId}/${filename}`;
  return uploadFile(path, file);
}

export function useAPIGetMessageImageUrl(message: Message): {
  url?: string;
  loading: boolean;
  error?: Error;
} {
  const path = message.imageInfo?.imagePath || null;
  return useStorageDownloadURL(path);
}

export function usePopulateMembers(chat?: Chat): {
  error?: Error;
  loading: boolean;
  members?: Readonly<Record<string, User>>;
} {
  const userIds = Object.keys(chat?.members ?? {})
    .sort()
    .join(",");
  const { data, error } = useSWR<Record<string, User>>(
    userIds ? ["populated:chat_member", userIds] : null,
    async ([_path, userIdsString]: [string, string]) => {
      const splittedUserIds = userIdsString.split(",");
      return getUsersByIds(splittedUserIds);
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  return {
    error,
    loading: !data && !error,
    members: data,
  };
}

export async function updateChatTitle(
  chatId: string,
  title: string,
): Promise<void> {
  const path = `chat/${chatId}`;
  const changes = {
    updated_at: serverTimestamp(),
    title,
  };
  try {
    await updateDoc(doc(fuego.db, path), changes);
    await mutate(path);
  } catch (err) {
    console.error("updateChatTitle/Error", err);
  }
}

export async function createGroupChat(
  members: User[],
  title = "",
): Promise<Chat> {
  const memberIds = members.map((member) => member.id);
  const chatId: string = await callCloudFunctions(
    FunctionNameV2.createGroupChat,
    {
      title,
      member: memberIds,
    },
  );
  console.log("createGroupChat/createdChat", chatId, memberIds);
  const path = `chat/${chatId}`;
  track(TrackEvent.chatCreated, {
    chat_id: chatId,
    chat_type: "direct",
    user_ids: memberIds,
    title: title,
  });
  const updated = await mutateDocument(path, chatDataConverter, true);
  if (!updated) {
    throw new Error("Error saving chat");
  }
  return updated;
}

export async function removeUserFromGroupChat(
  chat: Chat,
  userId: string,
): Promise<void> {
  await callCloudFunction(CloudFunctionName.removeMemberFromGroupChat, {
    chat_id: chat.id,
    member: [userId],
  });
  const path = `chat/${chat.id}`;
  await mutate(path);
}

export async function leaveGroupChat(chat: Chat): Promise<void> {
  await callCloudFunction(CloudFunctionName.leaveGroupChat, {
    chat_id: chat.id,
  });
  track(TrackEvent.chatLeft, { chatId: chat.id });

  const path = `chat/${chat.id}`;
  await mutate(path);

  const messagePath = `chat/${chat.id}/message`;
  await clearCollectionCache(messagePath);
}

export async function addMemberToGroupChat(
  chatId: string,
  userIds: string[],
): Promise<void> {
  await callCloudFunction(CloudFunctionName.addMemberToGroupChat, {
    chat_id: chatId,
    member: [...userIds],
  });
  const path = `chat/${chatId}`;
  await mutate(path);
}

export async function createDirectChat(
  loggedInUserId: string,
  userId: string,
): Promise<Chat> {
  let chatId: string;
  try {
    chatId = await callCloudFunctions(FunctionNameV2.createDirectChat, {
      userId,
    });
    console.log(
      `createDirectChat/loggedInUserId=${loggedInUserId}, userId=${userId}`,
    );

    track(TrackEvent.chatCreated, {
      chat_id: chatId,
      chat_type: "direct",
      user_id: userId,
    });
  } catch (err) {
    console.error("createDirectChat/Error", err);
    trackError(TrackErrorEvent.createDirectChat, err, {
      userId,
    });
    throw err;
  }
  const path = `chat/${chatId}`;
  const updated = await mutateDocument(path, chatDataConverter, true);

  if (!updated) {
    throw new Error("Error saving chat");
  }
  return updated;
}

export function useCreateDirectChat(
  chatId: string | undefined,
  loggedInUserId: string | undefined,
): ReadDocumentReturnType<Chat> {
  const { data, error } = useSWR(
    chatId ? `create:chat/${chatId}` : null,
    async () => {
      try {
        const snapshot = await getDoc(
          doc(fuego.db, `chat/${chatId}`).withConverter(
            getFirebaseDataConverter(chatDataConverter),
          ),
        );
        const chat = snapshot.data();
        if (chat) {
          return chat;
        }
      } catch (err) {}
      console.log("useCreateDirectChat/create", chatId);
      if (loggedInUserId && chatId) {
        const userId = chatId
          .split("_")
          .filter((id) => id !== loggedInUserId)[0];
        return createDirectChat(loggedInUserId, userId);
      }
    },
  );
  const createError = error === 1 ? undefined : error;
  const loading = !!chatId && !data && !createError;
  // console.log(
  //   `useCreateDirectChat/chatId=${chatId} loading=${loading} error=${createError}`
  // );
  return {
    data,
    error: createError,
    loading,
  };
}

export function getDraftFromLocalStorage(chatId?: string): string | null {
  if (!chatId) {
    return null;
  }

  const key = draftKey(chatId);
  try {
    return window.localStorage.getItem(key);
  } catch (e) {
    return null;
  }
}

export function saveDraftToLocalStorage(chatId?: string, value?: string): void {
  if (!chatId) {
    return;
  }
  const key = draftKey(chatId);
  if (value) {
    window.localStorage.setItem(key, value);
  } else {
    window.localStorage.removeItem(key);
  }
}

function draftKey(chatId: string): string {
  return "messageDraft" + chatId;
}

export async function toggleMessageReaction(
  loggedInUserId: string,
  message: Message,
  reaction?: string,
): Promise<void> {
  if (!message.reaction) {
    message.reaction = {};
  }
  if (reaction) {
    message.reaction[loggedInUserId] = new MessageReaction(
      loggedInUserId,
      reaction,
    );
    return addMessageReaction(
      loggedInUserId,
      message.chatId,
      message.id,
      reaction,
    );
  } else {
    delete message.reaction[loggedInUserId];
    return deleteMessageReaction(loggedInUserId, message.chatId, message.id);
  }
}

async function deleteMessageReaction(
  loggedInUserId: string,
  chatId: string,
  messageId: string,
): Promise<void> {
  const path = `chat/${chatId}/message/${messageId}`;

  const changes = {
    [`reaction.${loggedInUserId}`]: deleteField(),
    updated_at: serverTimestamp(),
  };

  try {
    void updateDoc(doc(fuego.db, path), changes);
  } catch (err) {
    console.error("deleteMessageReaction/error", err);
  }
  await mutate(path);
}

async function addMessageReaction(
  loggedInUserId: string,
  chatId: string,
  messageId: string,
  reaction: string,
): Promise<void> {
  const path = `chat/${chatId}/message/${messageId}`;

  const changes = {
    reaction: {
      [loggedInUserId]: {
        created_by_id: loggedInUserId,
        reaction,
        updated_at: serverTimestamp(),
      },
    },
    updated_at: serverTimestamp(),
  };

  try {
    void setDoc(doc(fuego.db, path), changes, { merge: true });
  } catch (err) {
    console.error("updateMessageReaction/error", err);
  }
  await mutate(path);
}
