import { customAlphabet, urlAlphabet } from "nanoid";
import Resizer from "react-image-file-resizer";

const nanoid = customAlphabet(urlAlphabet, 10);

export type ImageInfo = {
  file: File;
  width: number;
  height: number;
  hash: string;
};

const MAX_HEIGHT = 1024;
const MAX_WIDTH = 1024;

export const resizeImageFile = (file: File): Promise<ImageInfo> => {
  return checkImageFile(file).then(() => {
    return new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        MAX_WIDTH,
        MAX_HEIGHT,
        "JPEG",
        90,
        0,
        (uri) => {
          if (uri instanceof File) {
            const reader = new FileReader();
            reader.readAsDataURL(uri);
            reader.onload = () => {
              const img = new Image();
              img.src = reader.result as string;
              img.onload = () => {
                resolve({
                  file: uri,
                  width: img.width,
                  height: img.height,
                  hash: nanoid(),
                });
              };
            };
          } else {
            reject(new Error("resizeImageFile/error: wrong format"));
          }
        },
        "file",
      );
    });
  });
};

function checkImageFile(file: File): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        resolve(true);
      };
      img.onerror = () => {
        reject(new Error("Can't load image"));
      };
    };
    reader.onerror = () => {
      reject(new Error("Can't read file"));
    };
  });
}

export function downloadImage(url: string): Promise<ImageInfo> {
  return fetch(url)
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      const file = new File([blob], "image.jpg");
      return resizeImageFile(file);
    });
}
