import { toArray } from "lodash";

const r = new RegExp("\\p{Emoji}|\\p{Emoji_Component}|\\s", "gu");
export const isOnlyEmojis = (str: string): boolean => {
  return (
    toArray(str)
      .map((c) => {
        if (/^[0-9]*$/.test(str)) return c;
        return c.replace(r, "");
      })
      .join("").length === 0
  );
};
