import { ExpHub, ExperienceHubType } from "@sequoiacap/shared/models";
import {
  FunctionNameV2,
  useGetFromCloudFunctionInSuperJSON,
} from "./firebase/FirebaseAPI";
import { ReadDocumentReturnType } from "./firebase/types";

export function useAPIGetMyExperienceHubs(
  shouldGet: boolean,
  detailed?: boolean,
): ReadDocumentReturnType<{
  hubs: ExpHub[] | undefined;
  defaultArcCohortId: string | undefined;
  defaultScoutCampId: string | undefined;
  defaultBaseCampId: string | undefined;
}> {
  const functionName = FunctionNameV2.getMyExperienceHubs;
  const { data, error, loading } = useGetFromCloudFunctionInSuperJSON<ExpHub[]>(
    shouldGet ? functionName : undefined,
    { detailed },
    {
      refreshInterval: 60 * 60 * 1000,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return {
    data: {
      hubs: data,
      defaultArcCohortId: data?.filter(
        (h) => h.type === ExperienceHubType.arc,
      )?.[0]?.id,
      defaultScoutCampId: data?.filter(
        (h) => h.type === ExperienceHubType.scoutCamp,
      )?.[0]?.id,
      defaultBaseCampId: data?.filter(
        (h) => h.type === ExperienceHubType.baseCamp,
      )?.[0]?.id,
    },
    error,
    loading,
  };
}
