import { toArray } from "lodash";
import runes from "runes";

export const decodeBase64 = (str: string): string =>
  Buffer.from(str, "base64").toString("utf8");

export const encodeBase64 = (str: string): string =>
  Buffer.from(str).toString("base64");

export function firstN(text: string, maxLength: number): string {
  if (graphemeLength(text) <= maxLength) return text;
  return runes.substr(text, 0, maxLength) + "…";
}

export const graphemeLength = (str: string): number => toArray(str).length;
